import { Suspense, lazy } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./App.scss";

const Header = lazy(() => import("./Header"));
// const Footer = lazy(() => import("./Footer"));
// const Body = lazy(() => import('./Body'));
const BackgroundAnimation = lazy(() => import("./BackgroundAnimation"));

const App = () => {
  return (
    <Container fluid className='vh-100 p-0'>
      <Row className='d-flex flex-column flex-md-row h-100 g-0'>
        <Suspense fallback={<div>Please wait a moment</div>}>
          <Col
            xs='auto'
            lg='6'
            className='d-flex flex-column align-items-center justify-content-center h-100'
            style={{ position: "absolute" }}
          >
            <Header />
            {/* <Footer /> */}
          </Col>
          {/* <Col className="h-100">
            <Body />
          </Col> */}
          <BackgroundAnimation />
        </Suspense>
      </Row>
    </Container>
  );
};

export default App;
